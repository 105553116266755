import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { PUBLICLOGIN } from '../../authentication-lead.module';
import { Message } from 'primeng/api';

@Component({
  selector: 'fp-activation',
  templateUrl: './activation.component.html',
  styleUrl: './activation.component.scss',
})
export class ActivationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public authService: AuthenticationService
  ) {}

  isLoading = true;
  isVerified = false;
  PUBLICLOGIN = PUBLICLOGIN;
  messages: Message[] = [{ severity: 'success', detail: 'Your account has been successfully activated!' }];

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    const token = this.route.snapshot.paramMap.get('token');
    this.authService.validateUser(id, token).subscribe({
      next: res => {
        this.isLoading = false;
        this.isVerified = true;
      },
      error: err => {
        this.isLoading = false;
        this.isVerified = false;
      },
    });
  }
}
