import { createAction, props } from '@ngrx/store';
import { CareerEssential } from 'src/app/shared/types/goals';

export const careerEssentialSetInitial = createAction('[CareerEssential] Set initial');

// Load CareerEssentials
export const loadCareerEssentials = createAction('[CareerEssential] Load CareerEssentials');
export const loadCareerEssentialsSuccess = createAction(
  '[CareerEssential] Load CareerEssentials Success',
  props<{ careerEssentials: CareerEssential[] }>()
);
export const loadCareerEssentialsFailure = createAction('[CareerEssential] Load CareerEssentials Failure', props<{ error: string }>());

// Create CareerEssential
export const createCareerEssential = createAction(
  '[CareerEssential] Create CareerEssential',
  props<{ careerEssential: CareerEssential }>()
);
export const createCareerEssentialSuccess = createAction(
  '[CareerEssential] Create CareerEssential Success',
  props<{ careerEssential: CareerEssential }>()
);
export const createCareerEssentialFailure = createAction('[CareerEssential] Create CareerEssential Failure', props<{ error: string }>());

// Update CareerEssential
export const updateCareerEssential = createAction(
  '[CareerEssential] Update CareerEssential',
  props<{ careerEssentialId: number; careerEssential: Partial<CareerEssential> }>()
);
export const updateCareerEssentialSuccess = createAction(
  '[CareerEssential] Update CareerEssential Success',
  props<{ careerEssential: CareerEssential }>()
);
export const updateCareerEssentialFailure = createAction('[CareerEssential] Update CareerEssential Failure', props<{ error: string }>());
