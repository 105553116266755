import { Component, ElementRef, Input, OnChanges, QueryList, Renderer2, SimpleChanges, ViewChildren } from '@angular/core';
import { Conversation, ReadStatus } from '../../types/conversation.types';
import { Observable, filter, map, of, switchMap } from 'rxjs';
import { AppState } from 'src/app/state/reducers';
import { Store } from '@ngrx/store';
import { RELATIONS, SHAREDNOTE } from 'src/app/app-routing.module';
import { hasValue } from '../../utils';
import { ConversationService } from '../../services/conversation.service';
import { User } from '../../types/user.types';
import { MenteeRelation } from '../../types/mentee-relations.types';
import { Comment } from '../../types/comment.types';
import { selectMrById } from 'src/app/state/selectors/mentee-relations.selector';
import { UpdateConversation } from 'src/app/state/actions/conversation.actions';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HeaderComponent } from 'src/app/core/components/header/header.component';
import { OverlayPanel } from 'primeng/overlaypanel';

/**
 * Represents an app notification with its details.
 */
interface Notification {
  photo?: string;
  label?: string;
  role?: string;
  name: string;
  time: string;
  timeString: string;
  conversation: Conversation;
  read: boolean;
  link: string;
  message: string;
  field?: string;
}

/**
 * Represents the result of getNotifications.
 */
interface NotificationsResult {
  notifications: Notification[];
  unreadCount: number;
}

@Component({
  selector: 'fp-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss'],
})
export class ConversationsComponent implements OnChanges {
  constructor(
    private activatedRoute: ActivatedRoute,
    private conversationService: ConversationService,
    private headerComponent: HeaderComponent,
    private renderer: Renderer2,
    private router: Router,
    private store: Store<AppState>
  ) {}
  @ViewChildren('notificationDiv') notificationDivs: QueryList<ElementRef>;
  @Input() user: User;
  conversations$: Observable<Conversation[]> = this.store.select(state => state.conversations);
  currentTime = new Date();
  mrs$: Observable<MenteeRelation[]> = this.store.select(state => state.menteeRelations);
  notifications$: Observable<NotificationsResult>;
  RELATIONS = RELATIONS;
  showEmptyDiv: boolean = false;
  unreadConv: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    this.notifications$ = this.getNotifications();
  }

  getNotifications(): Observable<NotificationsResult> {
    return this.conversations$.pipe(
      filter(hasValue), // Filter out null or undefined
      map(conversations => this.processConversations(conversations))
    );
  }

  /**
   * Processes conversations to extract notifications and count unread notifications.
   * @param conversations An array of conversations.
   * @returns A NotificationsResult object.
   */
  private processConversations(conversations: any[]): NotificationsResult {
    const notifications: Notification[] = [];
    this.unreadConv = [];
    localStorage.removeItem('unreadConv'); // this should be treated in signOut, however it works  only if here too
    conversations.forEach(conversation => {
      if (conversation.notification) {
        this.processNotificationConversation(conversation, notifications);
      } else {
        this.processCommentConversation(conversation, notifications);
      }
    });

    return { notifications, unreadCount: this.unreadConv.length };
  }

  /**
   * Processes a conversation with a notification.
   * @param conversation The conversation with a notification.
   * @param notifications The array to store notifications.
   * @param unreadCount The count of unread notifications.
   */
  private processNotificationConversation(conversation: any, notifications: Notification[]): void {
    if (conversation.mentee_relation) {
      const mrData$ = this.findMenteeRelation(conversation.mentee_relation);
      mrData$
        .pipe(
          switchMap(mrData => {
            if (mrData) {
              let notification = {
                ...mrData,
                time: conversation.created_at,
                timeString: this.getTimeString(conversation.created_at),
                conversation: conversation,
                read: !this.isConversationUnread(conversation),
                link: this.getLink(conversation),
                message: conversation.notification.message,
              };

              // Check conversation.notification.id and conditionally change sender (remove label, change name)
              const idsToNotRemoveLabel = [16, 17, 18, 19];
              if (!idsToNotRemoveLabel.includes(conversation.notification.id)) {
                // Remove label property
                notification = {
                  ...notification,
                  label: undefined,
                  name: 'Femme Palette:',
                };
              }
              if (!notification.read) {
                this.updateUnreadCount(conversation.id);
              }
              if (!notifications.some(n => n.conversation.id === notification.conversation.id)) {
                notifications.push(notification);
              }
            }
            // Return empty observable to continue
            return of(null);
          })
        )
        .subscribe(() => {
          this.sortNotifications(notifications);
        });
    } else {
      const notification = {
        name: 'Femme Palette:',
        time: conversation.created_at,
        timeString: this.getTimeString(conversation.created_at),
        conversation: conversation,
        read: !this.isConversationUnread(conversation),
        link: this.getLink(conversation),
        message: conversation.notification.message,
      };
      if (!notification.read) {
        this.updateUnreadCount(conversation.id);
      }
      if (!notifications.some(n => n.conversation.id === notification.conversation.id)) {
        notifications.push(notification);
      }
      this.sortNotifications(notifications);
    }
  }

  /**
   * Processes a conversation with a comment.
   * @param conversation The conversation with a comment.
   * @param notifications The array to store notifications.
   * @param unreadCount The count of unread notifications.
   */
  private processCommentConversation(conversation: any, notifications: Notification[]): void {
    const youngestComment = this.findYoungestComment(conversation.comments, this.user?.id);
    if (youngestComment) {
      const mrData$ = this.findMenteeRelation(conversation.mentee_relation);
      mrData$
        .pipe(
          switchMap(mrData => {
            if (mrData) {
              let field = undefined;
              if (conversation.field.includes('shared_notes')) {
                field = conversation.field.replace(/shared_notes\d+/g, 'shared notes');
              } else if (conversation.field.includes('agenda')) {
                field = conversation.field.replace(/agenda\d+/g, 'agenda');
              } else {
                field = conversation.field.replace(/notes_(.*)/g, 'session notes');
              }
              const notification = {
                ...mrData,
                time: youngestComment.created_at,
                timeString: this.getTimeString(youngestComment.created_at),
                conversation: conversation,
                read: !this.isConversationUnread(conversation),
                field: conversation.field,
                message: ` left a comment in ${field}.`,
                link: conversation.field.includes('shared_notes')
                  ? SHAREDNOTE + conversation.mentee_relation
                  : RELATIONS + '/' + conversation.mentee_relation,
              };
              if (!notification.read) {
                this.updateUnreadCount(conversation.id);
              }
              if (!notifications.some(n => n.field === notification.field)) {
                notifications.push(notification);
              }
            }
            // Return empty observable to continue
            return of(null);
          })
        )
        .subscribe(() => {
          this.sortNotifications(notifications);
        });
    }
  }

  private findMenteeRelation(mrId: string): Observable<any> {
    return this.store.select(selectMrById(mrId)).pipe(
      filter(hasValue),
      map(mr => {
        if (mr) {
          if (this.user?.role === 'ME') {
            return {
              photo: mr.supporter.photo,
              name: `${mr.supporter.first_name} ${mr.supporter.last_name[0]}.`,
              label: mr.supporter?.first_name[0].toUpperCase() + mr.supporter?.last_name[0].toUpperCase(),
              role: mr.supporter.type,
            };
          } else {
            return {
              photo: mr.mentee.photo,
              name: `${mr.mentee.first_name} ${mr.mentee.last_name[0]}.`,
              label: mr.mentee?.first_name[0].toUpperCase() + mr.mentee?.last_name[0].toUpperCase(),
              role: 'mentee',
            };
          }
        } else {
          return null;
        }
      })
    );
  }

  // Sort notifications by time
  private sortNotifications(notifications: Notification[]): void {
    notifications.sort((a, b) => {
      const timeA = new Date(a.time).getTime();
      const timeB = new Date(b.time).getTime();
      return timeB - timeA;
    });
  }

  getLink(conversation: Conversation) {
    const link = conversation.notification.link;
    if (link === 'relation') {
      return RELATIONS + '/' + conversation.mentee_relation;
    } else if (link === 'selection') {
      return RELATIONS;
    } else {
      return link;
    }
  }

  updateUnreadCount(conversationId: string) {
    if (!this.unreadConv.includes(conversationId)) {
      this.unreadConv.push(conversationId);
      localStorage.setItem('unreadConv', JSON.stringify(this.unreadConv));
    }
  }

  private findYoungestComment(comments: Comment[], userId: string): Comment | null {
    let youngest: Comment | null = null;

    comments.forEach(comment => {
      if (comment.created_by.id !== userId) {
        if (!youngest || comment.created_at > youngest.created_at) {
          youngest = comment;
        }
      }
    });

    return youngest;
  }

  isConversationUnread(conversation: Conversation): boolean {
    // Check if userReadStatus does not exist or if read is false
    const userReadStatus = conversation.read_by.find(status => status.user === this.user?.id);
    return !userReadStatus || (userReadStatus && !userReadStatus.read);
  }

  setQueryParams(field: string): Params {
    // Get current QueryParams, if same as conversation field (user click the same div) then remove params
    let params = {};
    this.activatedRoute.queryParams.subscribe(values => {
      if (values['openOverlay'] === field) {
      } else {
        params = { openOverlay: field };
      }
    });
    return params;
  }

  onNotificationClick(notification: any, contactEmail: OverlayPanel, event: Event): void {
    // Mark the notification as read
    this.markOneRead(notification.conversation);
    // // Handle 'email' link case
    if (notification.link === 'email') {
      contactEmail.toggle(event);
      return;
    }
    // Handle navigation for other links
    if (notification.link) {
      const queryParams = notification.conversation?.field ? this.setQueryParams(notification.conversation.field) : null;
      this.router.navigate([notification.link], { queryParams });
    }
  }

  markOneRead(conversation: Conversation) {
    if (this.isConversationUnread(conversation)) {
      const index = this.unreadConv.indexOf(conversation.id);
      if (index !== -1) {
        this.unreadConv.splice(index, 1);
        localStorage.setItem('unreadConv', JSON.stringify(this.unreadConv));
      }
      const updateData: Partial<Conversation> = {
        read_by: [
          {
            user: this.user.id,
            read: true,
          },
        ] as ReadStatus[],
      };
      this.store.dispatch(new UpdateConversation(conversation.id, updateData));
    }
  }

  markAllRead() {
    this.unreadConv = [];
    localStorage.setItem('unreadConv', JSON.stringify(this.unreadConv));
    this.conversationService.markAllConversationsRead().subscribe();
    this.notificationDivs.forEach(notification => {
      this.renderer.removeClass(notification.nativeElement, 'unread-background');
    });
    this.showAll();
  }

  getTimeString(created_at: string) {
    const lastReactionTime = new Date(created_at);
    const difference = this.currentTime.getTime() - lastReactionTime.getTime();
    const millisecondsInMinute = 1000 * 60;
    const millisecondsInHour = millisecondsInMinute * 60;
    const millisecondsInDay = millisecondsInHour * 24;
    const millisecondsInWeek = millisecondsInDay * 7;
    if (difference < millisecondsInHour) {
      return Math.round(difference / millisecondsInMinute) + ' min ago';
    } else if (difference < millisecondsInDay) {
      return Math.round(difference / millisecondsInHour) + ' h ago';
    } else if (difference < millisecondsInWeek) {
      return Math.round(difference / millisecondsInDay) + ' days ago';
    } else {
      return Math.round(difference / millisecondsInWeek) + ' w ago';
    }
  }

  showAll() {
    this.notificationDivs.forEach(notification => {
      this.renderer.removeClass(notification.nativeElement, 'hidden');
    });
    this.showEmptyDiv = false;
    const unreadButton = document.querySelector('.p-button-text.number');
    if (unreadButton) {
      unreadButton.classList.remove('orange-background');
    }
  }

  showOnlyUnread() {
    this.notificationDivs.forEach(notification => {
      const classesArray = Array.from(notification.nativeElement.classList);
      if (!classesArray.includes('unread-background')) {
        this.renderer.addClass(notification.nativeElement, 'hidden');
      }
    });
    if (this.unreadConv.length === 0) {
      this.showEmptyDiv = true;
    }
    const unreadButton = document.querySelector('.p-button-text.number');
    if (unreadButton) {
      unreadButton.classList.add('orange-background');
    }
  }

  getUnreadConvFromLocalStorage(): string[] {
    const unreadConvString = localStorage.getItem('unreadConv');
    return unreadConvString ? JSON.parse(unreadConvString) : [];
  }

  closeNotifications() {
    this.headerComponent.displayConversations = false;
  }
}
