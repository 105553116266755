import { Injectable } from '@angular/core';
import { User } from '../types/user.types';
import { AppState } from 'src/app/state/reducers';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CAREERPLANNER, COMPANY, DASHBOARD, RELATIONS, RELOAD } from 'src/app/app-routing.module';
import { filter, first, lastValueFrom } from 'rxjs';
import { hasValue } from '../utils';
import { UtilsService } from './utils.service';
import { OriginalUrlService } from './original-url.service';
import { UserService } from './user.service';
import { UpdateUser } from 'src/app/state/actions/settings.actions';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private utilsService: UtilsService,
    private originalUrlService: OriginalUrlService,
    private userService: UserService
  ) {}

  async processLogin(userRole: User['role']) {
    this.utilsService.loadData2Store(userRole);
    // Retrieve the original URL from the service
    const originalUrl = this.originalUrlService.getOriginalUrl();
    if (originalUrl) {
      // Navigate to the original URL
      this.router.navigate([originalUrl]);
    } else {
      await this.navigateLogged(userRole);
    }
  }

  async navigateLogged(userRole: User['role']) {
    if (userRole == 'CC') {
      this.router.navigate([COMPANY]);
    } else if (userRole == 'LD') {
      this.router.navigate([CAREERPLANNER]);
    } else {
      const mrs$ = this.store.select(state => state.menteeRelations).pipe(filter(hasValue), first());
      const mrs = await lastValueFrom(mrs$);
      if (mrs && Object.keys(mrs).length !== 0) {
        this.router.navigate([`../${RELATIONS}/${mrs[0].id}`]);
      } else {
        this.router.navigate([DASHBOARD]);
      }
    }
  }

  async navigateToRelation() {
    try {
      const item = await lastValueFrom(this.store.select(state => state.menteeRelations).pipe(first()));
      const mrs_len = item ? Object.keys(item).length : 0;
      if (mrs_len > 0) {
        this.router.navigate([`../${RELATIONS}/${item[0].id}`]);
      } else {
        this.router.navigate([DASHBOARD]);
      }
    } catch (err) {
      this.router.navigate([DASHBOARD]);
    }
  }

  async changeUser(user: User, userRole: string) {
    try {
      this.userService.updateUserRole(user.id, userRole).subscribe({
        next: res => {
          localStorage.setItem('user_role', userRole);
          this.store.dispatch(new UpdateUser({ ...user, role: userRole }));
          this.utilsService.clearStore();
          this.processLogin(userRole);
          const currentUrl = this.router.url;
          this.router.navigate([RELOAD], { state: { returnUrl: currentUrl } });
        },
      });
    } catch (error) {
      console.error('Error changing user role:', error);
    }
  }
}
