export type User = {
  id?: string;
  username: string;
  email?: string;
  roles?: number[];
  role?: string;
  last_used_role?: string;
  first_name?: string;
  last_name?: string;
};

export const roleMapping = {
  1: { code: 'AD', label: 'Admin' },
  2: { code: 'MR', label: 'Mentor' },
  3: { code: 'CH', label: 'Coach' },
  4: { code: 'ME', label: 'Mentee' },
  5: { code: 'CC', label: 'Company Client' },
  6: { code: 'LD', label: 'Lead' },
};
