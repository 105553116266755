import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export const selectMentee = (state: AppState) => state.mentees;

// Reduce to find the mentee with the most recent 'created_at'
export const selectLastMentee = () =>
  createSelector(selectMentee, allMentees => {
    if (allMentees) {
      return allMentees.reduce((youngest, current) => {
        return !youngest || new Date(current.created_at) > new Date(youngest.created_at) ? current : youngest;
      }, null);
    } else {
      return undefined;
    }
  });
