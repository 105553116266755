import { Component } from '@angular/core';
import { AbstractControlOptions, UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { TERMS } from 'src/app/app-routing.module';
import { PUBLICLOGIN } from '../../authentication-lead.module';

@Component({
  selector: 'fp-signup',
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss',
})
export class SignupComponent {
  constructor(
    private formBuilder: UntypedFormBuilder,
    public authService: AuthenticationService,
    private notificationService: NotificationService
  ) {}
  PUBLICLOGIN = PUBLICLOGIN;
  TERMS = TERMS;
  signupPage = 'signup';
  userEmail = '';
  createAccountForm = this.formBuilder.group(
    {
      username: [null, [Validators.required]],
      email: [null, [Validators.required]],
      password: [null, [Validators.required, Validators.minLength(8)]],
    },
    {
      validator: this.checkPassword('password'),
    } as AbstractControlOptions
  );

  checkPassword(password: string) {
    return function (frm) {
      let passwordValue = frm.get(password).value;
      if (!isNaN(passwordValue) && !isNaN(parseFloat(passwordValue))) {
        return {
          notCorrect: `Password can’t be entirely numeric.`,
        };
      }
      return null;
    };
  }

  createAccount() {
    if (this.createAccountForm.valid) {
      this.notificationService.loading = true;
      let userObj = { ...this.createAccountForm.value, last_used_role: 'LD' };
      this.authService.signUp(userObj).subscribe({
        next: res => {
          this.createAccountForm.reset();
          this.userEmail = res['email'];
          this.signupPage = 'waitingVerification';
          this.notificationService.loading = false;
        },
        error: err => {
          let msg = '';
          if (typeof err.error === 'object') {
            for (let key in err.error) {
              if (Array.isArray(err.error[key])) {
                let joinedMessages = err.error[key].join(' ');
                msg += `${key.toUpperCase()}: ${joinedMessages}`;
              }
            }
          } else {
            msg = err.error;
          }
          this.notificationService.openSnackBar('error', msg);
          this.notificationService.loading = false;
        },
      });
    } else {
      this.createAccountForm.markAllAsTouched();
    }
  }
}
