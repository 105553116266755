<div class="center">
  <ng-container *ngIf="(notifications$ | async)?.['notifications']?.length; then hasNotifications; else noNotifications"> </ng-container>

  <ng-template #hasNotifications>
    <div class="border-bottom">
      <h2 class="mt-0 mb-2 text-20">
        Notifications
        <p-button
          icon="pi pi-times"
          styleClass="p-button-warning p-button-rounded p-button-text close-button absolute top-0 right-0"
          (onClick)="closeNotifications()"
        ></p-button>
      </h2>
      <div class="flex flex-wrap align-items-center justify-content-between">
        <div class="flex">
          <button (click)="showAll()" class="underline p-button p-button-text text-xs" data-umami-event="Show all conversations">
            All
          </button>
          <button
            (click)="showOnlyUnread()"
            class="number p-button p-button-text text-xs"
            data-umami-event="Show only unread conversations"
          >
            {{ getUnreadConvFromLocalStorage().length.toString() }}
          </button>
        </div>
        <button
          (click)="markAllRead()"
          pButton
          pRipple
          class="p-button-text text-xs"
          [disabled]="getUnreadConvFromLocalStorage().length === 0"
          data-umami-event="Mark all conversations read"
        >
          Mark all as read
        </button>
      </div>
    </div>
    <ng-container *ngFor="let notification of (notifications$ | async)?.notifications; let first = first">
      <div
        #notificationDiv
        class="notification"
        [class.unread-background]="!notification.read"
        [class.first-element]="first"
        (click)="onNotificationClick(notification, contactEmail, $event)"
      >
        <div class="flex">
          <p-avatar
            *ngIf="notification.label"
            [image]="notification.photo ? notification.photo : undefined"
            [label]="!notification.photo ? notification.label : undefined"
            shape="circle"
            onselectstart="false"
            class="noselect text-sm mr-2"
          ></p-avatar>
          <p-avatar
            *ngIf="!notification.label"
            image="assets/f_logo.png"
            shape="circle"
            onselectstart="false"
            class="noselect text-sm mr-2"
          ></p-avatar>
          <div class="flex flex-column text-sm">
            <p class="m-0">
              <b>{{ notification.name }}</b> {{ notification.message }}
            </p>
            <span class="block text-xs">{{ notification.timeString }}</span>
          </div>
        </div>
        <p-overlayPanel #contactEmail [dismissable]="true" [showCloseIcon]="true">
          <div>mentoring&#64;femmepalette.com</div>
        </p-overlayPanel>
      </div>
    </ng-container>
    <div *ngIf="showEmptyDiv" class="text-sm text-center m-5">You don’t have any unread notifications.</div>
  </ng-template>
</div>

<ng-template #noNotifications>
  <div class="text-sm text-center">You don’t have any notifications.</div>
</ng-template>
