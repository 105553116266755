import { Component } from '@angular/core';
import { Navigation, Router } from '@angular/router';

@Component({
  selector: 'fp-reload',
  templateUrl: './reload.component.html',
  styleUrl: './reload.component.scss',
})
export class ReloadComponent {
  constructor(private router: Router) {
    const nav: Navigation = this.router.getCurrentNavigation();

    if (nav.extras && nav.extras.state) {
      if (nav.extras.state['returnUrl']) {
        this.router.navigateByUrl(nav.extras.state['returnUrl']);
      }
    }
  }
}
