import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Conversation } from '../types/conversation.types';
import { environment } from 'src/environments/environment';
import { Role } from '../types/mentee-relations.types';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  constructor(private http: HttpClient) {}

  public getConversations() {
    const role = localStorage.getItem('user_role');
    return this.http.get<Conversation[]>(environment.apiUrl + '/conversation/user/?user_role=' + role);
  }

  public updateConversation(conversationId: string, updatedConversation: Partial<Conversation>) {
    return this.http.patch<Conversation>(environment.apiUrl + '/conversation/' + conversationId + '/', updatedConversation);
  }

  public createConversation(submissionId: string, notifiedRole: Role, field?: string, notificationId?: number, mrId?: string) {
    return this.http.post<Conversation>(environment.apiUrl + '/conversation/', {
      submissionId,
      field,
      notificationId,
      mrId,
      notifiedRole,
    });
  }

  public markAllConversationsRead() {
    const role = localStorage.getItem('user_role');
    return this.http.get<Conversation[]>(environment.apiUrl + '/conversation/mark_all_read/?user_role=' + role);
  }
}
