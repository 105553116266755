<div [ngSwitch]="signupPage">
  <div *ngSwitchCase="'signup'">
    <form autocomplete="off" class="p-fluid text-sm" [formGroup]="createAccountForm" (ngSubmit)="createAccount()">
      <div class="field mb-3">
        <label>Username</label>
        <input formControlName="username" type="text" pInputText />
        <div
          *ngIf="createAccountForm.invalid && (createAccountForm.get('username').dirty || createAccountForm.get('username').touched)"
          class="error-box"
        >
          <div *ngIf="createAccountForm.get('username').errors?.['required']">Username is required.</div>
        </div>
      </div>
      <div class="field mb-3">
        <label>Email</label>
        <input formControlName="email" type="email" pInputText />
        <div
          *ngIf="createAccountForm.invalid && (createAccountForm.get('email').dirty || createAccountForm.get('email').touched)"
          class="error-box"
        >
          <div *ngIf="createAccountForm.get('email').errors?.['required']">Email is required.</div>
        </div>
      </div>
      <div class="field mb-4">
        <label>Password</label>
        <p-password
          id="password"
          formControlName="password"
          autocomplete="one-time-code"
          [toggleMask]="true"
          [feedback]="false"
        ></p-password>
        <div
          *ngIf="!createAccountForm.get('password').dirty && !createAccountForm.get('password').touched"
          class="mt-1 text-xs text-black-alpha-60"
        >
          Minimum length is 8 characters.
        </div>
        <div
          *ngIf="createAccountForm.invalid && (createAccountForm.get('password').dirty || createAccountForm.get('password').touched)"
          class="error-box mt-1"
        >
          <div *ngIf="createAccountForm.get('password').errors?.['required'] || createAccountForm.get('password').errors?.['minlength']">
            Minimum length is 8 characters.
          </div>

          <div *ngIf="createAccountForm.errors?.['notCorrect']">{{ createAccountForm.errors?.['notCorrect'] }}</div>
        </div>
      </div>
      <button pButton pRipple type="submit" label="Create Account" class="p-button-warning"></button>
      <p class="text-xs mt-2">
        By clicking "Create Account" I agree with the
        <a [href]="TERMS" target="_blank" class="underline">General Terms and Conditions</a> of the platform. By providing your contact
        details, you consent to receiving marketing emails from Femme Palette. You can unsubscribe anytime.
      </p>
    </form>
    <footer class="yellow-back text-sm font-bold text-center -mx-5 -mb-6 mt-4 p-3">
      <p class="mb-0">Already have an account? <a [href]="PUBLICLOGIN" class="underlined" style="cursor: pointer">Log in</a></p>
    </footer>
  </div>
  <div *ngSwitchCase="'waitingVerification'" class="text-center">
    <h2>Verify Your Email</h2>
    <p class="text-sm mb-3">
      For added security, you'll need to verify your identity in a one quick step. We've sent a verification email to
      <strong>{{ userEmail }}</strong
      >. Please check your inbox and click on the link to verify your account.
    </p>

    <!-- <button pButton label="Resend Activation Link" icon="pi pi-envelope" (click)="resendActivationLink()"></button> -->

    <p class="text-xs">
      If you have any issues, contact our support team at <a href="mailto:help@femmepalette.com">help&#64;femmepalette.com</a>
    </p>
  </div>
</div>
