import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DeepLink } from '../types/deeplink.types';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public deepLinkRegister(deepLink: DeepLink, login: string, password: string) {
    return this.http.post<HttpResponse<any>>(
      environment.apiUrl + '/users/dl_register/',
      {
        deeplink: deepLink.id,
        login,
        password,
      },
      { observe: 'response' }
    );
  }

  public deleteAccount(user, deleteAccountReason: string) {
    return this.http.patch<HttpResponse<any>>(environment.apiUrl + '/users/delete_account/', { user, deleteAccountReason });
  }

  public updateUserRole(userId: string, userLastRole: string) {
    return this.http.patch<HttpResponse<any>>(environment.apiUrl + '/users/update_role/', { userId, userLastRole });
  }
}
