import { createAction, props } from '@ngrx/store';
import { LongGoal } from 'src/app/shared/types/goals';

export const longGoalSetInitial = createAction('[LongGoal] Set initial');

// Load LongGoals
export const loadLongGoals = createAction('[LongGoal] Load LongGoals');
export const loadLongGoalsSuccess = createAction('[LongGoal] Load LongGoals Success', props<{ goals: LongGoal[] }>());
export const loadLongGoalsFailure = createAction('[LongGoal] Load LongGoals Failure', props<{ error: string }>());

// Create LongGoal
export const createLongGoal = createAction('[LongGoal] Create LongGoal', props<{ goal: LongGoal }>());
export const createLongGoalSuccess = createAction('[LongGoal] Create LongGoal Success', props<{ goal: LongGoal }>());
export const createLongGoalFailure = createAction('[LongGoal] Create LongGoal Failure', props<{ error: string }>());

// Update LongGoal
export const updateLongGoal = createAction('[LongGoal] Update LongGoal', props<{ goalId: number; goal: Partial<LongGoal> }>());
export const updateLongGoalSuccess = createAction('[LongGoal] Update LongGoal Success', props<{ goal: LongGoal }>());
export const updateLongGoalFailure = createAction('[LongGoal] Update LongGoal Failure', props<{ error: string }>());
