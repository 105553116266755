import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { TERMS } from 'src/app/app-routing.module';
import { LEADSIGNUP } from '../../authentication-lead.module';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'fp-lead-login',
  templateUrl: './lead-login.component.html',
  styleUrl: './lead-login.component.scss',
})
export class LeadLoginComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    public authService: AuthenticationService,
    private notificationService: NotificationService,
    private navigation: NavigationService
  ) {}
  loginForm: UntypedFormGroup;
  resetPasswordForm: UntypedFormGroup;
  createAccountForm: UntypedFormGroup;
  invalidLogin = false;
  authPage = 'login';
  message: string;
  errorMessage: boolean;
  TERMS = TERMS;
  LEADSIGNUP = LEADSIGNUP;

  async ngOnInit() {
    this.loginForm = this.formBuilder.group({
      login: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
    this.resetPasswordForm = this.formBuilder.group({
      email: [null, [Validators.required]],
    });
    const user = await this.authService.getMe();
    if (user) {
      await this.navigation.processLogin(user.role);
    }
  }

  public onType() {
    if (this.loginForm.get('password').value !== '') {
      this.invalidLogin = false;
    }
  }

  async login() {
    if (this.loginForm.valid) {
      const username = this.loginForm.get('login').value;
      const password = this.loginForm.get('password').value;
      const user = await this.authService.logIn(username, password);
      if (user) {
        await this.navigation.processLogin(user.role);
      } else {
        this.invalidLogin = true;
        this.loginForm.reset();
        const el = document.getElementById('login');
        el.focus();
        this.notificationService.openSnackBar('error', 'Incorrect username or password. Please try again.');
      }
    }
  }

  resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.notificationService.loading = true;
      const email = this.resetPasswordForm.get('email').value;
      this.authService.resetPassword(email).subscribe({
        next: res => {
          this.errorMessage = false;
          this.message = `Password reset email has been sent to <span class="font-bold">${email}</span> if email exists in our database`;
          this.notificationService.loading = false;
          this.resetPasswordForm.reset();
        },
        error: err => {
          if (err.status == 400) {
            this.message = err.error[Object.keys(err.error)[0]];
            this.errorMessage = true;
            this.notificationService.loading = false;
          } else {
            this.errorMessage = false;
            this.message = `Password reset email has been sent to <span class="font-bold">${email}</span> if email exists in our database`;
            this.notificationService.loading = false;
            this.resetPasswordForm.reset();
          }
        },
      });
    }
  }
}
