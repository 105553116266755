<!-- Display a loading spinner while verification is in progress -->
<div *ngIf="isLoading" class="flex justify-content-center"><p-progressSpinner></p-progressSpinner></div>

<!-- Display success message -->
<div *ngIf="!isLoading && isVerified" class="success-message flex flex-column justify-content-center">
  <p-messages [(value)]="messages" [closable]="false"></p-messages>
  <button pButton label="Go to Login" class="p-button-warning mt-4" [routerLink]="['/' + PUBLICLOGIN]"></button>
</div>

<!-- Display error message -->
<div *ngIf="!isLoading && !isVerified" class="error-message text-center">
  <p-messages severity="error">
    <ng-template pTemplate>
      <div>
        Verification failed.<br />Please try again or contact support at
        <a href="mailto:help@femmepalette.com">help&#64;femmepalette.com</a>.
      </div>
    </ng-template>
  </p-messages>

  <!-- <button pButton label="Resend Activation Link" icon="pi pi-envelope" (click)="resendActivationLink()"></button> -->
</div>
