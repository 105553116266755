import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';
import { SETTINGS, COMPANY, DASHBOARD, CAREERPLANNER, RELOAD } from 'src/app/app-routing.module';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Subscription, filter, first } from 'rxjs';
import { hasValue } from 'src/app/shared/utils';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { roleMapping, User } from 'src/app/shared/types/user.types';
import { selectLastMentee } from 'src/app/state/selectors/mentee.selector';

@Component({
  selector: 'fp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnChanges, OnDestroy {
  constructor(
    private authService: AuthenticationService,
    private messageService: MessageService,
    private navigationService: NavigationService,
    public notificationCenter: NotificationService,
    private store: Store<AppState>
  ) {}
  CAREERPLANNER = CAREERPLANNER;
  COMPANY = COMPANY;
  DASHBOARD = DASHBOARD;
  RELOAD = RELOAD;
  displayConversations = false;
  label: string;
  logo;
  public menuItems: MenuItem[];
  modelSubscription: Subscription;
  photo;
  headerLoading: boolean = true;
  @Input() user: User;

  async ngOnChanges(changes: SimpleChanges) {
    this.headerLoading = true;

    switch (this.user?.role) {
      case 'ME':
        this.updateUserRoleData('mentee');
        break;
      case 'MR':
        this.updateUserRoleData('mentor');
        break;
      case 'CH':
        this.updateUserRoleData('coach');
        break;
      case 'CC':
        this.modelSubscription = this.store
          .select(state => state.company)
          .subscribe(company => {
            this.label = company?.name.slice(0, 2).toUpperCase();
            this.headerLoading = false;
            if (company?.logo) {
              this.logo = company.logo;
            }
          });
        break;
      case 'LD':
        this.label = `${this.user?.username[0]}`.toUpperCase();
        this.headerLoading = false;
        break;
    }

    this.menuItems = [
      { label: 'Settings', routerLink: SETTINGS },
      { label: 'Log out', command: () => this.signOut() },
    ];
    if (this.user?.role == 'LD') {
      this.menuItems = [{ label: 'Career Planner', routerLink: CAREERPLANNER }, ...this.menuItems];
    }

    let roles = this.user?.roles;
    if (this.user?.roles?.length > 1) {
      // Filter out roles that are equal to the `user` role
      const filteredRoles = roles.filter(role => role !== 1 && roleMapping[role]?.code !== this.user?.role);
      // Create menu items based on filtered roles
      const menuItemsMoreRoles = filteredRoles.map(role => ({
        label: `${roleMapping[role]?.label} profile`,
        command: () => this.navigationService.changeUser(this.user, roleMapping[role].code),
      }));
      this.menuItems = [...menuItemsMoreRoles, ...this.menuItems];
    }
  }

  updateUserRoleData(role) {
    const roleData$ = role === 'mentee' ? this.store.select(selectLastMentee()) : this.store.select(state => state[role]);
    // Handle the first emission and execute the "photoLoading" logic
    roleData$.pipe(filter(hasValue), first()).subscribe({
      next: roleData => {
        if (roleData?.photo) {
          this.photo = roleData.photo;
        } else {
          this.photo = undefined;
          this.label = `${roleData?.first_name[0]}${roleData?.last_name[0]}`.toUpperCase();
        }
      },
      error: () => {
        this.headerLoading = false;
      },
      complete: () => {
        this.headerLoading = false;
      },
    });

    // Continue to subscribe for further updates
    this.modelSubscription = roleData$.subscribe({
      next: roleData => {
        if (roleData?.photo) {
          this.photo = roleData.photo;
        } else {
          this.photo = undefined;
          this.label = `${roleData?.first_name[0]}${roleData?.last_name[0]}`.toUpperCase();
        }
      },
    });
  }

  onConfirm() {
    this.messageService.clear('c');
  }

  onReject() {
    this.messageService.clear('c');
  }

  clear() {
    this.messageService.clear();
  }

  navigateToRelation() {
    this.navigationService.navigateToRelation();
  }

  signOut() {
    this.authService.signOut();
  }

  toggleConversations() {
    this.displayConversations = !this.displayConversations;
  }

  getUnreadConvFromLocalStorage(): string[] {
    const unreadConvString = localStorage.getItem('unreadConv');
    return unreadConvString ? JSON.parse(unreadConvString) : [];
  }

  ngOnDestroy() {
    this.modelSubscription?.unsubscribe();
  }
}
