import { Component } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
@Component({
  selector: 'fp-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent {
  constructor(public notificationService: NotificationService) {}
}
