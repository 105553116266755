import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupComponent } from './components/signup/signup.component';
import { ActivationComponent } from './components/activation/activation.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AuthenticationComponent } from '../authentication/containers/authentication/authentication.component';
import { LEADAUTH } from '../app-routing.module';
import { LeadLoginComponent } from './components/lead-login/lead-login.component';

export const LEADSIGNUP = LEADAUTH + '/signup';
export const LEADACTIVATION = LEADAUTH + '/activate';
export const PUBLICLOGIN = LEADAUTH + '/login';

@NgModule({
  declarations: [SignupComponent, ActivationComponent, LeadLoginComponent],
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: AuthenticationComponent,
        children: [
          { path: '', component: LeadLoginComponent },
          { path: 'signup', component: SignupComponent },
          { path: 'activate/:id/:token', component: ActivationComponent },
          { path: 'login', component: LeadLoginComponent },
        ],
      },
    ]),
    CommonModule,
    SharedModule,
  ],
})
export class AuthenticationLeadModule {}
