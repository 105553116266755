import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export const selectMrs = (state: AppState) => state.menteeRelations;

export const selectMrById = (id: string) =>
  createSelector(selectMrs, allMrs => {
    if (allMrs) {
      return allMrs.find(obj => obj.id == id);
    } else {
      return undefined;
    }
  });

export const selectActiveMentorRelation = createSelector(selectMrs, allMrs => {
  if (allMrs) {
    return allMrs.find(mr => (mr?.mr_status === 10 || mr?.mr_status === 11) && mr?.supporter?.type === 'mentor');
  } else {
    return undefined;
  }
});
