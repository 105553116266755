import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { AppState } from 'src/app/state/reducers';
import { Store } from '@ngrx/store';
import { UpdateUser } from 'src/app/state/actions/settings.actions';
import { OriginalUrlService } from './original-url.service';
import { hasValue } from '../utils';
import { filter, first } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { PUBLICLOGIN } from 'src/app/authentication-lead/authentication-lead.module';

@Injectable()
export class AuthGuardService {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private store: Store<AppState>,
    private originalUrlService: OriginalUrlService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = await this.authService.getMe();
    if (user) {
      this.store.dispatch(new UpdateUser(user));
      return true;
    } else {
      // Store the original URL in the service
      this.originalUrlService.setOriginalUrl(state.url);
      // Redirect to the login page
      this.router.navigate([PUBLICLOGIN]);
      return false;
    }
  }
}

@Injectable()
export class PlatformGuard {
  constructor(
    private store: Store<AppState>,
    private authGuardService: AuthGuardService,
    private router: Router
  ) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const canActivateBasic = await this.authGuardService.canActivate(route, state);
    if (canActivateBasic) {
      const user = await lastValueFrom(this.store.select(state => state.settings.user).pipe(filter(hasValue), first()));
      return user && user.role != 'LD' ? true : this.router.navigateByUrl('/');
    } else {
      return false;
    }
  }
}

@Injectable()
export class MentorSectionGuard {
  constructor(
    private store: Store<AppState>,
    private authGuardService: AuthGuardService,
    private router: Router
  ) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const canActivateBasic = await this.authGuardService.canActivate(route, state);

    if (canActivateBasic) {
      const user = await lastValueFrom(this.store.select(state => state.settings.user).pipe(filter(hasValue), first()));
      return user && user.role == 'MR' ? true : this.router.navigateByUrl('/');
    } else {
      return false;
    }
  }
}

@Injectable()
export class MentorOrCoachSectionGuard {
  constructor(
    private store: Store<AppState>,
    private authGuardService: AuthGuardService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const canActivateBasic = await this.authGuardService.canActivate(route, state);
    if (canActivateBasic) {
      const user = await lastValueFrom(this.store.select(state => state.settings.user).pipe(filter(hasValue), first()));
      return user && (user.role == 'CH' || user.role == 'MR') ? true : this.router.navigateByUrl('/');
    } else {
      return false;
    }
  }
}

@Injectable()
export class CompanySectionGuard {
  constructor(
    private store: Store<AppState>,
    private authGuardService: AuthGuardService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const canActivateBasic = await this.authGuardService.canActivate(route, state);
    if (canActivateBasic) {
      const user = await lastValueFrom(this.store.select(state => state.settings.user).pipe(filter(hasValue), first()));
      return user && user.role == 'CC' ? true : this.router.navigateByUrl('/');
    } else {
      return false;
    }
  }
}
