import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { first, lastValueFrom } from 'rxjs';
import { ConversationService } from 'src/app/shared/services/conversation.service';
import { EmailLogService } from 'src/app/shared/services/email-log.service';
import { SmartGoalsService } from 'src/app/shared/services/smart-goals.service';
import { MenteeRelation } from 'src/app/shared/types/mentee-relations.types';
import { Mentee } from 'src/app/shared/types/mentee.types';
import { UpdateMentee } from 'src/app/state/actions/mentee.actions';
import { AppState } from 'src/app/state/reducers';
import { selectLastMentee } from 'src/app/state/selectors/mentee.selector';

@Component({
  selector: 'fp-goals-generator',
  templateUrl: './goals-generator.component.html',
  styleUrls: ['./goals-generator.component.scss'],
})
export class GoalsGeneratorComponent {
  constructor(
    private conversationService: ConversationService,
    private emailLogService: EmailLogService,
    private smartGoalsService: SmartGoalsService,
    private store: Store<AppState>
  ) {}
  @Input() source: string = 'public';
  dialogDisplay = false;
  innerDialogDisplay = false;
  goalsSubmited = false;
  loadingGenerate = false;
  loadingSubmit = false;
  requestFailed = false;
  requestInvalid = false;
  showEditor = false;
  showErrorBox = false;

  email = new FormControl('', { validators: [Validators.required] });
  generatedGoals = new FormControl('', { validators: [Validators.required, Validators.minLength(150)] });
  goalsUserInput = new FormControl('', { validators: [Validators.required, Validators.minLength(6)] });

  generateSmartGoals() {
    if (this.goalsUserInput.valid) {
      this.loadingGenerate = true;
      this.smartGoalsService.generateSmartGoals(this.goalsUserInput.value).subscribe({
        next: res => {
          if (res == 'no response') {
            this.requestInvalid = true;
          } else {
            this.generatedGoals.setValue(res.replace(/\n/g, '<br/>'));
            this.showEditor = true;
          }
          this.loadingGenerate = false;
        },
        error: err => {
          this.loadingGenerate = false;
          this.showErrorBox = true;
          this.requestFailed = true;
        },
      });
    } else {
      this.showErrorBox = true;
    }
  }

  submitSmartGoals() {
    if (this.generatedGoals.valid) {
      this.loadingSubmit = true;
      this.smartGoalsService.submitSmartGoals(this.email.value, this.generatedGoals.value).subscribe({
        next: () => {
          this.dialogDisplay = false;
          this.goalsSubmited = true;
          this.showEditor = false;
          this.generatedGoals.reset();
          this.loadingSubmit = false;
        },
        error: err => {
          this.dialogDisplay = false;
          this.goalsSubmited = true;
          this.showEditor = false;
          this.generatedGoals.reset();
          this.loadingSubmit = false;
        },
      });
    } else {
      this.showErrorBox = true;
    }
  }

  async saveInnerGoals() {
    const mentee: Mentee = await lastValueFrom(this.store.select(selectLastMentee()).pipe(first()));
    const goals: Partial<Mentee> = { mentee_goals: this.generatedGoals.value };
    this.store.dispatch(new UpdateMentee(mentee.id, goals));
    this.generatedGoals.valid ? (this.innerDialogDisplay = true) : (this.innerDialogDisplay = false);
  }

  async notifyMentor() {
    this.loadingSubmit = true;
    try {
      const relations: MenteeRelation[] = await lastValueFrom(this.store.select(state => state.menteeRelations).pipe(first()));
      if (relations) {
        // find active relation with mentor (not coach)
        const relation = relations.find(relation => relation.supporter.type === 'mentor');
        if (relation.supporter.user_account) {
          // create conversation with notification id=16 == edited goals
          this.conversationService
            .createConversation(relation.mentee.submission_id, relation.supporter, undefined, 16, relation.id)
            .subscribe(() => {
              this.goalsSubmittedBooleansChange();
            });
        } else {
          // create an email and send it to mentor without user account
          this.emailLogService
            .newEmailLogs(relation.id, [105], relation.supporter.type)
            .subscribe(() => this.goalsSubmittedBooleansChange());
        }
      } else {
        this.goalsSubmittedBooleansChange();
      }
    } catch (err) {
      this.goalsSubmittedBooleansChange();
    }
  }

  goalsSubmittedBooleansChange() {
    this.innerDialogDisplay = false;
    this.loadingSubmit = false;
  }
}
