import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CitiesLoad } from 'src/app/state/actions/cities.actions';
import { CountriesLoad } from 'src/app/state/actions/countries.actions';
import { EnvironmentsLoad } from 'src/app/state/actions/environments.actions';
import { FieldsLoad } from 'src/app/state/actions/fields.actions';
import { GenderLoad } from 'src/app/state/actions/gender.actions';
import { LanguagesLoad } from 'src/app/state/actions/languages.actions';
import { MenteeLoad, MenteeSetInitial, UpdateMentee } from 'src/app/state/actions/mentee.actions';
import { MentorLoad, UpdateMentor } from 'src/app/state/actions/mentor.actions';
import { PersonalAttributesLoad } from 'src/app/state/actions/personal-attributes.actions';
import { SkillsLoad } from 'src/app/state/actions/skills.actions';
import { TeamSizesLoad } from 'src/app/state/actions/team-sizes.actions';
import { AppState } from 'src/app/state/reducers';
import { getDirtyValues } from '../utils';
import { Mentor } from '../types/mentor.types';
import { UntypedFormGroup } from '@angular/forms';
import { Mentee } from '../types/mentee.types';
import { CompanyLoad, CompanyUpdate } from 'src/app/state/actions/company.actions';
import { Company } from '../types/company.types';
import { CoachLoad, CoachUpdate } from 'src/app/state/actions/coach.actions';
import { Coach } from '../types/coach.types';
import { StatusesLoad } from 'src/app/state/actions/statuses.actions';
import { LoadGoalStatuses } from 'src/app/state/actions/goal-statuses.actions';
import { loadShortGoals } from 'src/app/state/actions/short-goals.actions';
import { loadLongGoals } from 'src/app/state/actions/long-goals.actions';
import { loadCareerEssentials } from 'src/app/state/actions/career-essentials.actions';
import { MenteeRelationSetInitial } from 'src/app/state/actions/mentee-relations.actions';
import { MentorSetInitial } from 'src/app/state/actions/mentor.actions';
import { CompanySetInitial } from 'src/app/state/actions/company.actions';
import { ConversationSetInitial } from 'src/app/state/actions/conversation.actions';
import { SessionsSetInitial } from 'src/app/state/actions/sessions.actions';
import { CoachSetInitial } from 'src/app/state/actions/coach.actions';
import { careerEssentialSetInitial } from 'src/app/state/actions/career-essentials.actions';
import { longGoalSetInitial } from 'src/app/state/actions/long-goals.actions';
import { shortGoalSetInitial } from 'src/app/state/actions/short-goals.actions';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private store: Store<AppState>) {}

  loadData2Store(user_role: string) {
    localStorage.setItem('user_role', user_role);
    if (user_role == 'ME') {
      this.store.dispatch(new MenteeLoad());
    } else if (user_role == 'MR' || user_role == 'CH') {
      this.store.dispatch(new GenderLoad());
      this.store.dispatch(new LanguagesLoad());
      this.store.dispatch(new SkillsLoad());
      this.store.dispatch(StatusesLoad());
      if (user_role == 'MR') {
        this.store.dispatch(new MentorLoad());
        this.store.dispatch(new CitiesLoad());
        this.store.dispatch(new CountriesLoad());
        this.store.dispatch(new EnvironmentsLoad());
        this.store.dispatch(new FieldsLoad());
        this.store.dispatch(new PersonalAttributesLoad());
        this.store.dispatch(new TeamSizesLoad());
      } else if (user_role == 'CH') {
        this.store.dispatch(CoachLoad());
      }
    } else if (user_role == 'CC') {
      this.store.dispatch(new CompanyLoad());
    }
    if (user_role !== 'CC') {
      this.store.dispatch(LoadGoalStatuses());
      this.store.dispatch(loadShortGoals());
      this.store.dispatch(loadLongGoals());
      this.store.dispatch(loadCareerEssentials());
    }
  }

  clearStore() {
    this.store.dispatch(new MenteeSetInitial());
    this.store.dispatch(new MentorSetInitial());
    this.store.dispatch(new CompanySetInitial());
    this.store.dispatch(new MenteeRelationSetInitial());
    this.store.dispatch(new SessionsSetInitial());
    this.store.dispatch(new ConversationSetInitial());
    this.store.dispatch(CoachSetInitial());
    this.store.dispatch(shortGoalSetInitial());
    this.store.dispatch(longGoalSetInitial());
    this.store.dispatch(careerEssentialSetInitial());
  }

  updateRawInstance(type: string, modelId: string, model: any) {
    if (type == 'mentee') {
      this.store.dispatch(new UpdateMentee(modelId, model));
    } else if (type === 'mentor') {
      this.store.dispatch(new UpdateMentor(modelId, model));
    } else if (type === 'coach') {
      this.store.dispatch(CoachUpdate({ coachId: modelId, coach: model }));
    }
  }

  updateDetails(type: string, modelId: string, form: UntypedFormGroup) {
    if (form.valid) {
      let dirtyValues = getDirtyValues(form);
      if (type === 'mentee') {
        const mentee: Partial<Mentee> = {
          ...dirtyValues,
        };
        this.store.dispatch(new UpdateMentee(modelId, mentee));
      } else if (type === 'mentor') {
        const mentor: Partial<Mentor> = {
          ...dirtyValues,
        };
        this.store.dispatch(new UpdateMentor(modelId, mentor));
      } else if (type === 'coach') {
        const coach: Partial<Coach> = {
          ...dirtyValues,
        };
        this.store.dispatch(CoachUpdate({ coachId: modelId, coach }));
      } else if (type === 'company') {
        const company: Partial<Company> = {
          ...dirtyValues,
        };
        this.store.dispatch(new CompanyUpdate(modelId, company));
      }
    }
  }
}
