import { createAction, props } from '@ngrx/store';
import { ShortGoal } from 'src/app/shared/types/goals';

export const shortGoalSetInitial = createAction('[ShortGoal] Set initial');

// Load ShortGoals
export const loadShortGoals = createAction('[ShortGoal] Load ShortGoals');
export const loadShortGoalsSuccess = createAction('[ShortGoal] Load ShortGoals Success', props<{ goals: ShortGoal[] }>());
export const loadShortGoalsFailure = createAction('[ShortGoal] Load ShortGoals Failure', props<{ error: string }>());

// Create ShortGoal
export const createShortGoal = createAction('[ShortGoal] Create ShortGoal', props<{ goal: ShortGoal }>());
export const createShortGoalSuccess = createAction('[ShortGoal] Create ShortGoal Success', props<{ goal: ShortGoal }>());
export const createShortGoalFailure = createAction('[ShortGoal] Create ShortGoal Failure', props<{ error: string }>());

// Update ShortGoal
export const updateShortGoal = createAction('[ShortGoal] Update ShortGoal', props<{ goalId: number; goal: Partial<ShortGoal> }>());
export const updateShortGoalSuccess = createAction('[ShortGoal] Update ShortGoal Success', props<{ goal: ShortGoal }>());
export const updateShortGoalFailure = createAction('[ShortGoal] Update ShortGoal Failure', props<{ error: string }>());

// Delete ShortGoal
export const deleteShortGoal = createAction('[ShortGoal] Delete ShortGoal', props<{ goalId: number }>());
export const deleteShortGoalSuccess = createAction('[ShortGoal] Delete ShortGoal Success', props<{ goalId: number }>());
export const deleteShortGoalFailure = createAction('[ShortGoal] Delete ShortGoal Failure', props<{ error: string }>());
