<p-progressBar
  *ngIf="notificationService.loading"
  mode="indeterminate"
  [style]="{ height: '3px', position: 'fixed' }"
></p-progressBar>
<p-toast></p-toast>
<div class="flex justify-content-center pt-6">
  <div class="py-6 px-5 login-card">
    <div class="flex justify-content-center pb-6">
      <img tabindex="-1" style="width: 250px" src="assets/FP-logo.png" alt="Femme Palette Logo" />
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
