<div [ngSwitch]="authPage">
  <div *ngSwitchCase="'login'">
    <form autocomplete="off" class="p-fluid text-sm" [formGroup]="loginForm" (ngSubmit)="login()">
      <div class="field">
        <label>Your username</label>
        <input id="login" formControlName="login" type="text" pInputText />
      </div>
      <div class="field mb-4">
        <label>Password</label>
        <p-password
          id="password"
          formControlName="password"
          [ngClass]="{ 'has-error': invalidLogin }"
          (keyup)="onType()"
          [toggleMask]="true"
          [feedback]="false"
        ></p-password>
      </div>
      <button pButton pRipple type="submit" label="Log in" class="p-button-warning"></button>
      <p class="text-xxs mt-2">
        I agree with the <a [href]="TERMS" target="_blank" class="underline">General Terms and Conditions</a> of the platform.
      </p>
    </form>
    <div (click)="authPage = 'resetPassword'" class="text-sm underlined mt-3" style="cursor: pointer">Forgot your password?</div>

    <footer class="yellow-back text-sm font-bold text-center -mx-5 -mb-6 mt-4 p-3">
      <p class="mb-0">Don’t have an account yet? <a [href]="LEADSIGNUP" class="underlined" style="cursor: pointer">Create one.</a></p>
    </footer>
  </div>

  <div *ngSwitchCase="'resetPassword'">
    <form autocomplete="off" class="p-fluid text-sm" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
      <div class="field mb-4">
        <label>Your email</label>
        <input formControlName="email" type="text" pInputText />
      </div>
      <button pButton pRipple type="submit" label="Reset Password" class="p-button-warning"></button>
      <p *ngIf="message" class="py-3 text-sm" [ngClass]="errorMessage ? 'error-box' : 'success-box text-center'" [innerHTML]="message"></p>
      <p *ngIf="!message" class="text-xxs mt-2">
        I agree with the <a [href]="TERMS" target="_blank" class="underline">General Terms and Conditions</a> of the platform.
      </p>
    </form>
    <div (click)="authPage = 'login'" class="text-sm underlined mt-3" style="cursor: pointer">Login to your account</div>
  </div>
</div>
