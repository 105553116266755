import { Action } from '@ngrx/store';
import { Session } from 'src/app/shared/types/session.types';

export const SESSIONS_SET_INITIAL = '[SESSIONS] Set initial';
export const SESSIONS_LOAD = '[SESSIONS] Load sessions';
export const SESSIONS_LOAD_SUCCESS = '[SESSIONS] Load sessions successful';
export const SESSIONS_LOAD_ERROR = '[SESSIONS] Load sessions failed';
export const SESSION_LOAD = '[SESSION] Load session';
export const SESSION_LOAD_SUCCESS = '[SESSION] Load session successful';
export const SESSION_LOAD_ERROR = '[SESSION] Load session failed';
export const UPDATE_SESSION = '[SESSIONS] Update session';
export const UPDATE_SESSION_SUCCESS = '[SESSIONS] Update session successful';
export const UPDATE_SESSION_ERROR = '[SESSIONS] Update session failed';

export class SessionsSetInitial implements Action {
  readonly type = SESSIONS_SET_INITIAL;
}
export class SessionsLoad implements Action {
  readonly type = SESSIONS_LOAD;
  constructor(
    public role: string,
    public ids: string[]
  ) {}
}

export class SessionsLoadSuccess implements Action {
  readonly type = SESSIONS_LOAD_SUCCESS;
  constructor(public payload: Session[]) {}
}

export class SessionsLoadError implements Action {
  readonly type = SESSIONS_LOAD_ERROR;
}

export class SessionLoad implements Action {
  readonly type = SESSION_LOAD;
  constructor(public id: string) {}
}

export class SessionLoadSuccess implements Action {
  readonly type = SESSION_LOAD_SUCCESS;
  constructor(public payload: Session) {}
}

export class SessionLoadError implements Action {
  readonly type = SESSION_LOAD_ERROR;
}

export class SessionUpdate implements Action {
  readonly type = UPDATE_SESSION;
  constructor(
    public id: string,
    public session: Partial<Session>
  ) {}
}

export class SessionUpdateSuccess implements Action {
  readonly type = UPDATE_SESSION_SUCCESS;
  constructor(public payload: Session) {}
}

export class SessionUpdateError implements Action {
  readonly type = UPDATE_SESSION_ERROR;
}

export type Actions =
  | SessionsSetInitial
  | SessionsLoad
  | SessionsLoadSuccess
  | SessionsLoadError
  | SessionLoad
  | SessionLoadSuccess
  | SessionLoadError
  | SessionUpdate
  | SessionUpdateSuccess
  | SessionUpdateError;
